import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import { NormalText, SmallText, Subtitle } from '../common/index.js';
import moment from 'moment';
import './index.css';
import { checkmark, close } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

class OrderStatus extends Component {
	render() {
		const { __, events } = this.props;
		return (
			<>
				<Subtitle>{__('Order status')}</Subtitle>
				<div className="order-status-details">
					{events.map((event) => (
						<div className="order-status-details-line">
							<div>
								{event.order_status.toLowerCase().includes('fail') ? (
									<IonIcon className="order-status-failed" icon={close} color="white"></IonIcon>
								) : (
									<IonIcon className="order-status-success" icon={checkmark} color="white"></IonIcon>
								)}
							</div>
							<div className="box-content">
								<SmallText className="block">{moment(event.timestamp).format('DD/MM/YYYY at LT')}</SmallText>
								<NormalText>{__(`${event.order_status.toLowerCase() == 'new' ? 'Created' : event.order_status}`)}</NormalText>
							</div>
						</div>
					))}
				</div>
			</>
		);
	}
}

export default withTranslation(OrderStatus);
