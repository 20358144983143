import { Capacitor, Plugins } from '@capacitor/core';
import { IonAlert, IonApp, IonContent, IonPage, IonSplitPane } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { withTranslation } from '../src/lib/translate';
import { getConfig } from './appConfig';
import Alert from './components/alert';
import Drawer from './components/drawer';
import ErrorAlert from './components/errorAlert';
import ProtectedRoute from './components/protectedRoute';
import Loading from './components/spinner';
import Toast from './components/toast';
import ValidateModal from './components/validateModal';
import history from './history';
import Basket from './lib/basket';
import { forwardTo, getDefaultRoute, isWebConfig } from './lib/utils';
import { changeConnectionStatus, init, setCommonModal, setCommonProp, setMyLocation, updateProfile } from './store/actions';
import ErrorPage from './screens/errorPage';
import NotFound from './screens/notFound';
import TableNumberModal from './components/tableNumberModal';

require('./theme/' + (isWebConfig() ? 'web' : 'index') + '.css');
const { Network, Geolocation } = Plugins;

Plugins.App.addListener('backButton', () => {
	if (history.location && history.location.pathname && getConfig().general.appExitRoutes.indexOf(history.location.pathname) !== -1) {
		Plugins.App.exitApp();
	} else {
		history.goBack();
	}
});

const mapRoutes = (routes, extraProps = {}) => {
	return routes
		.filter((route) => !!route.path && !!route.component)
		.map((item) => {
			const { path } = item;
			const ActualRoute = item.protected ? ProtectedRoute : Route;
			return (
				<ActualRoute
					exact={item.exact}
					path={path}
					key={'nav-key-' + path}
					route={item}
					cmp={item.component}
					render={(props) => (item.render ? item.render({ ...props, ...extraProps, route: item }) : <item.component {...props} {...extraProps} scheduled={item.scheduled} route={item} />)}
				/>
			);
		});
};

class App extends React.Component {
	constructor(props) {
		super(props);

		this.defaultRoute = [];
		this.routeComponents = [];
		this.authRouteComponents = [];
		this.additionalRouteComponents = [];
		this.notInMenuRouteComponents = [];

		this.content = null;
	}

	componentDidMount() {
		this.props.dispatch(init());

		Network.addListener('networkStatusChange', (conStatus) => {
			const status = conStatus.connected;
			this.props.dispatch(changeConnectionStatus(status));
		});
		this.getCurrentPosition();
	}

	async getCurrentPosition() {
		const { myLocation, dispatch } = this.props;
		if (!myLocation.latitude && !myLocation.longitude) {
			// this.showModal(true)
			try {
				const coordinates = await Geolocation.getCurrentPosition({
					enableHighAccuracy: false,
				});
				const myLocation = {
					latitude: coordinates.coords.latitude,
					longitude: coordinates.coords.longitude,
				};
				dispatch(setMyLocation(myLocation));
			} catch (error) {
				if (JSON.stringify(error).indexOf('kCLErrorDomain') !== -1 && JSON.stringify(error).indexOf('error 0') !== -1) {
					this.getCurrentPosition();
				}
			}
		}
	}
	onCollectionTimeModalClose = () => {
		const { dispatch } = this.props;
		Basket.reset();
		dispatch(setCommonModal('changeCollectionTimeModalOpen', false));
	};
	onCollectionTimeModalHandler = () => {
		const { dispatch } = this.props;
		forwardTo(Basket.delivery_option.route, { collectionTimeRestaurant: Basket.getRestaurant().id });
		dispatch(setCommonModal('changeCollectionTimeModalOpen', false));
	};
	onRedeemGiftVoucherModalClose = () => {
		const { dispatch } = this.props;
		dispatch(setCommonModal('isRedeemGiftVoucherModalOpen', false));
	};
	onRemoveValidateModal = () => {
		const { dispatch } = this.props;
		dispatch(setCommonModal('isValidationModalOpen', false));
		dispatch(updateProfile({ is_verification_pop_up_shown: true }, true));
	};

	onRemoveBasketResetModal = () => {
		const { dispatch } = this.props;
		dispatch(setCommonModal('isBasketResetModalOpen', false));
		dispatch(setCommonModal('hasBaksetResetModalOpen', true));
	};

	generateRoutes = () => {
		this.defaultRoute = getDefaultRoute(this.props.navConfig);
		this.routeComponents = mapRoutes(this.props.navConfig.routes);
		this.authRouteComponents = mapRoutes(this.props.navConfig.authRoutes);
		this.additionalRouteComponents = mapRoutes(this.props.navConfig.additionalRoutes);
		this.notInMenuRouteComponents = mapRoutes(this.props.navConfig.notInMenuRoutes);
	};

	clearBasket = () => {
		const { dispatch } = this.props;
		Basket.reset();
		dispatch(setCommonModal('isBasketResetWarningModalOpen', false));
	};

	clearPickUpBasket = () => {
		const { dispatch } = this.props;
		Basket.reset();
		dispatch(setCommonModal('removeBasketItemsModalOpen', { showAlert: false }));
		forwardTo('/delivery-options');
	};
	cancelDriverBooking = () => {
		const { dispatch } = this.props;
		Basket.reset();
		forwardTo('/delivery-options');
		dispatch(setCommonModal('driverUnavailableModal', false));
	};
	closeAlert = () => {
		const { dispatch } = this.props;
		forwardTo('/order');
		dispatch(setCommonModal('isBasketResetWarningModalOpen', false));
	};

	render() {
		const {
			__,
			isValidationModalOpen,
			initLoading,
			isBasketResetModalOpen,
			isBasketResetWarningModalOpen,
			removeBasketItemsModalOpen,
			redeemGiftVoucherMessage,
			isRedeemGiftVoucherModalOpen,
			driverUnavailableModal,
			changeCollectionTimeModalOpen,
		} = this.props;
		if (this.props.showErrorPage) {
			this.content = null;
		} else {
			if (Capacitor.platform === 'web') {
				// wait for init saga to finish (reason: we dont have splash screen in web)
				if (!initLoading) {
					this.generateRoutes();
					this.content = null;
				} else {
					this.content = (
						<IonPage id="main">
							<IonContent>
								<Loading additionalLoadingCondition />
							</IonContent>
						</IonPage>
					);
				}
			} else {
				// mobile
				if (!initLoading) {
					this.generateRoutes();
					this.content = null;
				} else {
					this.content = (
						<IonPage id="main">
							<IonContent>
								<Loading additionalLoadingCondition />
							</IonContent>
						</IonPage>
					);
				}
			}
		}
		return (
			<IonApp className={isWebConfig() ? 'web' : ''}>
				<Router history={history}>
					{this.props.showErrorPage ? (
						<ErrorPage />
					) : (
						<>
							{this.content ? (
								this.content
							) : (
								<IonSplitPane {...(isWebConfig() ? { when: '(min-width:768px)' } : { when: '(min-width:2000px)' })} contentId="main">
									<Drawer />
									<IonPage id="main">
										<Switch>
											{this.routeComponents}
											{this.authRouteComponents}
											{this.additionalRouteComponents}
											{this.notInMenuRouteComponents}
											{this.defaultRoute ? <Route exact path="/" render={() => <Redirect to={this.defaultRoute.path} />} /> : null}
											<Route component={NotFound}></Route>
										</Switch>
										<Toast />
										<ErrorAlert />
										<ValidateModal />
										<IonAlert
											isOpen={isRedeemGiftVoucherModalOpen}
											onDidDismiss={this.onRedeemGiftVoucherModalClose}
											header={__('Success')}
											message={__(redeemGiftVoucherMessage)}
											buttons={[
												{
													text: __('Close'),
													role: 'cancel',
													cssClass: 'secondary',
													handler: this.onRedeemGiftVoucherModalClose,
												},
											]}
										/>
										<IonAlert
											isOpen={isValidationModalOpen}
											onDidDismiss={this.onRemoveValidateModal}
											header={__('Success')}
											message={__('The app is now unlocked to redeem your loyalty')}
											buttons={[
												{
													text: __('Close'),
													role: 'cancel',
													cssClass: 'secondary',
													handler: this.onRemoveValidateModal,
												},
											]}
										/>
										<IonAlert
											isOpen={isBasketResetModalOpen}
											onDidDismiss={this.onRemoveBasketResetModal}
											header={__('Basket')}
											message={__('You must checkout in the next 5 minutes to place your order.')}
											buttons={[
												{
													text: __('OK'),
													role: 'cancel',
													cssClass: 'secondary',
													handler: () => this.onRemoveBasketResetModal,
												},
											]}
										/>
										<IonAlert
											isOpen={removeBasketItemsModalOpen?.showAlert}
											onDidDismiss={this.clearPickUpBasket}
											header={__('Basket')}
											message={
												removeBasketItemsModalOpen.message
													? __(removeBasketItemsModalOpen.message)
													: __('Your collection time has now passed, please select diferent collection time and place your order.')
											}
											buttons={[
												{
													text: __('OK'),
													role: 'cancel',
													cssClass: 'secondary',
													handler: () => this.clearPickUpBasket,
												},
											]}
										/>
										<IonAlert
											isOpen={driverUnavailableModal}
											onDidDismiss={this.cancelDriverBooking}
											header={__('Delivery Unavailable')}
											message={__('No drivers currently available. Please try again later.')}
											buttons={[
												{
													text: __('OK'),
													role: 'cancel',
													cssClass: 'secondary',
													handler: () => this.cancelDriverBooking,
												},
											]}
										/>
										<IonAlert
											isOpen={changeCollectionTimeModalOpen}
											backdropDismiss={false}
											onDidDismiss={() => {}}
											header={__('Selected time is no longer available')}
											buttons={[
												{
													text: __('Cancel this order'),
													cssClass: 'secondary',
													role: 'cancel',
													handler: () => this.onCollectionTimeModalClose(),
												},
												{
													text: __('Select different time'),
													cssClass: 'secondary',
													handler: () => this.onCollectionTimeModalHandler(),
												},
											]}
										/>
										<Alert showAlert={isBasketResetWarningModalOpen} closeAlert={this.closeAlert} type="select" clearBasket={this.clearBasket} />

										<IonAlert
											isOpen={this.props.guestUserActionsModalOpen}
											onDidDismiss={() => {
												this.props.dispatch(setCommonProp('guestUserActionsModalOpen', false));
											}}
											backdropDismiss={false}
											cssClass={'alert-bottom'}
											header={__('Have an account?')}
											buttons={[
												{
													text: __('Sign in'),
													cssClass: 'primary',
													handler: () => {
														this.props.dispatch(setCommonProp('guestUserActionsModalOpen', false));
														forwardTo('/login', { referrer: `${history.location.pathname}${history.location.search ?? ''}` });
													},
												},
												{
													text: __('Register'),
													cssClass: 'primary',
													handler: () => {
														this.props.dispatch(setCommonProp('guestUserActionsModalOpen', false));

														forwardTo('/register', { referrer: `${history.location.pathname}${history.location.search ?? ''}` });
													},
												},
												{
													text: __('Order as guest'),
													cssClass: 'primary',
													role: 'cancel',
												},
											]}
										></IonAlert>
										{this.props.tableNumberModalOpened && <TableNumberModal />}
									</IonPage>
								</IonSplitPane>
							)}
						</>
					)}
				</Router>
			</IonApp>
		);
	}
}

const stateToProps = (store) => {
	const { auth, profile } = store.profile;
	const {
		myLocation,
		initLoading,
		navConfig,
		isValidationModalOpen,
		isBasketResetModalOpen,
		hasBaksetResetModalOpen,
		removeBasketItemsModalOpen,
		isBasketResetWarningModalOpen,
		redeemGiftVoucherMessage,
		isRedeemGiftVoucherModalOpen,
		driverUnavailableModal,
		showErrorPage,
		changeCollectionTimeModalOpen,
		guestUserActionsModalOpen,
		tableNumberModalOpened,
	} = store.common;
	return {
		auth,
		myLocation,
		profile,
		initLoading,
		navConfig,
		isValidationModalOpen,
		isBasketResetModalOpen,
		removeBasketItemsModalOpen,
		hasBaksetResetModalOpen,
		isBasketResetWarningModalOpen,
		redeemGiftVoucherMessage,
		isRedeemGiftVoucherModalOpen,
		driverUnavailableModal,
		showErrorPage,
		changeCollectionTimeModalOpen,
		guestUserActionsModalOpen,
		tableNumberModalOpened,
	};
};

export default connect(stateToProps)(withTranslation(App));
