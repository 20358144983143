import { IonAlert, IonButton, IonCheckbox, IonIcon, IonItem, IonList, IonRadio, IonRadioGroup, IonTextarea } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FlexSpacer, NormalText, Spacer, Subtitle, Title } from '../../components/common';
import Incrementer from '../../components/incrementer';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import { PillGroup } from '../../components/pill';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { deepCopy, forwardTo, getLocale, isDefined, isEmptyObject, isWebConfig, makeKey } from '../../lib/utils';
import { storeItemWeb } from '../../store/actions';
import './index.css';

const { formatPrice, _calculateItemPrice, validateItem, addToBasket, getProductName, getProductDescription, isProductEnabled, isProductJustEnabled, isChoicesGroupValid, setAllergen } = Basket;

class itemDetailsContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quantity: 1,
			price: 0,
			selectedChoices: [],
			validationErrors: [],
			showValidationAlert: false,
			allergensCodes: [],
			specialInstructionsModalOpen: false,
			instructions: '',
			modalInstructions: '',
		};
	}

	componentDidMount() {
		const { profile, allergens, item } = this.props;
		let quantity = this.state.quantity;
		let selectedChoices = [];
		let validationErrors = [];

		if (item.menuDealGroups && item.menuDealGroups.length > 0) {
			selectedChoices = Array(item.menuDealGroups.length).fill([]);
			validationErrors = Array(item.menuDealGroups.length).fill(null);
		}
		let price = _calculateItemPrice({ item, quantity });
		let locale = getLocale(profile);
		if (locale === 'fr') {
			locale = 'fr_FR';
		}
		let allergensCodes = item ? (item.itemRichData ? ((item.itemRichData.allergenCodes && item.itemRichData.allergenCodes.length) > 0 ? item.itemRichData.allergenCodes : []) : []) : [];
		let newArr = [];
		allergensCodes.map((allergenCode) => {
			let dataArr = (allergens && allergens.data ? allergens.data : []).find((allergen) => {
				let dataArr2 = null;
				if (allergen.code === allergenCode) {
					dataArr2 = allergen.translations.find((data) => {
						if (data.locale === locale) {
							newArr.push(data.text);
							return data;
						}
						return null;
					});
				}
				return dataArr2;
			});
			if (!dataArr) {
				newArr.push(allergenCode);
			}
			return null;
		});

		this.setState({ price, selectedChoices, validationErrors, allergensCodes: newArr }, () => {
			const initialValidationErrors = validateItem(this.constructBasketItem());
			this.setState({ validationErrors: initialValidationErrors.errors });
		});
	}

	onIncrementerUpdate = (newQuantity) => {
		this.setState({
			quantity: newQuantity,
			price: _calculateItemPrice({
				item: this.props.item,
				quantity: newQuantity,
				selectedChoices: this.state.selectedChoices,
			}),
		});
	};

	updateSelectedChoices = (groupIndex, choiceIndex, multiSelectionPermitted, selected = true, quantity = 0) => {
		// const item = this.props.location.state
		const { item } = this.props;
		const { selectedChoices } = this.state;
		const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
		const selectedGroup = allGroups[groupIndex];
		if (selectedGroup) {
			const selectedChoice = selectedGroup.items[choiceIndex] ? deepCopy(selectedGroup.items[choiceIndex]) : {};
			let updatedSelectedChoices = deepCopy(selectedChoices);
			let selectedChoiceIndex = -1;
			const existingChoice = updatedSelectedChoices[groupIndex].find((i, index) => {
				if (selectedChoice && i.sku === selectedChoice.sku) {
					selectedChoiceIndex = index;
					return true;
				}
				return false;
			});
			if (multiSelectionPermitted) {
				//checkbox
				if (selected) {
					selectedChoice.quantity = 1;
					if (existingChoice) {
						existingChoice.quantity = quantity;
						if (selectedChoiceIndex !== -1 && updatedSelectedChoices[groupIndex] && updatedSelectedChoices[groupIndex][selectedChoiceIndex]) {
							updatedSelectedChoices[groupIndex][selectedChoiceIndex] = existingChoice;
						}
					} else {
						updatedSelectedChoices[groupIndex].push(selectedChoice);
					}
				} else {
					selectedChoice.quantity = 0;
					updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter((i) => i.sku !== selectedChoice.sku);
				}
			} else {
				if (selected) {
					selectedChoice.quantity = quantity;
					updatedSelectedChoices[groupIndex] = [selectedChoice];
				} else {
					selectedChoice.quantity = 0;
					updatedSelectedChoices[groupIndex] = [];
				}
			}

			this.setState({ selectedChoices: updatedSelectedChoices }, () => {
				const { selectedChoices } = this.state;
				const newCalculatedPrice = _calculateItemPrice({
					item,
					quantity: this.state.quantity,
					selectedChoices,
				});
				this.setState(
					{
						price: _calculateItemPrice({
							item,
							quantity: this.state.quantity,
							selectedChoices,
						}),
					},
					() => {
						if (this.props.priceUpdated) {
							this.props.priceUpdated(newCalculatedPrice);
						}
						const validationErrors = validateItem(this.constructBasketItem());
						this.setState({ validationErrors: validationErrors.errors });
					},
				);
			});
		}
	};
	instructionsChange = (event) => this.setState({ modalInstructions: event.target.value });

	handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
		const item = this.props.item;
		const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
		const selectedGroup = allGroups[groupIndex];
		if (selectedGroup) {
			const selectedChoice = selectedGroup.items[choiceIndex];
			this.updateSelectedChoices(groupIndex, choiceIndex, multiSelectionPermitted, multiSelectionPermitted ? event.target.checked : !!selectedChoice, selectedChoice ? 1 : 0);
		}
	};

	onIncrementerChoiceUpdate = (newQuantity, data) => {
		const { item } = this.props;
		const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
		const selectedGroup = allGroups[data.groupIndex];
		if (selectedGroup) {
			this.updateSelectedChoices(data.groupIndex, data.choiceIndex, data.multiSelectionPermitted, newQuantity > 0, newQuantity);
		}
	};
	drawGroupChoices = (menuGroupItem, multiSelectionPermitted, groupIndex) => {
		let { items, max, maximumSelection, multiMax } = menuGroupItem;
		max = max ?? maximumSelection;
		const choices = items || [];
		const { selectedChoices } = this.state;
		const { profile, __ } = this.props;
		const allChoices = choices.map((item, choiceIndex) => {
			const { sku, productPrice } = item;
			const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => {
				return i.sku === sku;
			});
			if (item.sku === undefined || (getConfig().flags.showSnoozedProducts ? !isProductJustEnabled(item) : !isProductEnabled(item))) {
				return <span key={item.sku + '_disabled'} />;
			}
			const groupQuantity = selectedChoices[groupIndex] && selectedChoices[groupIndex].length > 0 ? selectedChoices[groupIndex].reduce((sum, i) => sum + i.quantity || 0, 0) : 0;
			let quantity = 0;
			if (selectedChoices[groupIndex]) {
				const _choice = selectedChoices[groupIndex].find((i) => i.sku === sku);
				quantity = _choice ? _choice.quantity : 0;
			}
			let groupQunatity = 0;
			(selectedChoices[groupIndex] || []).forEach((i) => {
				groupQunatity += isDefined(i.quantity) ? i.quantity : 1;
			});
			const shouldBeDisabled = isDefined(max) && max > 0 && isDefined(selectedChoices[groupIndex]) && groupQunatity >= max && !isChecked;

			const allergensCodes = item?.itemRichData?.allergenCodes || [];
			let locale = getLocale(profile);

			let allergensData = [];
			if (allergensCodes.length > 0) {
				allergensData = allergensCodes.map((allergen) => {
					const allergenData = this.props?.allergens?.data?.find((el) => el.code == allergen);
					const translation = allergenData?.translations && allergenData?.translations?.find((translation) => translation.locale == locale);
					if (translation) {
						return translation.text;
					} else {
						return allergen;
					}
				});
			}
			if (menuGroupItem.hasOwnProperty('multiMax')) {
				return (
					<div className={`sub-item ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''} `} key={makeKey(choiceIndex, sku, groupIndex)}>
						<IonItem tabIndex="-1" lines="none">
							{Basket.isProductUnsnoozed(item) ? (
								<>
									{multiSelectionPermitted ? (
										<IonCheckbox
											disabled={shouldBeDisabled}
											className="details"
											color="primary"
											slot="start"
											checked={isChecked}
											onIonChange={(event) => {
												this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event);
											}}
										/>
									) : (
										<IonRadio slot="start" className={'details' + (isChecked ? ' primary-radio' : '')} color="primary" value={choiceIndex} checked={isChecked} />
									)}
								</>
							) : (
								<div className="checkbox-placeholder"></div>
							)}
							<div className="ion-text-wrap up">
								<NormalText className="single-item item-details-product-label">{__(getProductName(item, profile))}</NormalText>
								{allergensData && allergensData.length > 0 ? (
									<>
										<PillGroup items={allergensData} borderColor="primary" />
									</>
								) : null}
							</div>
						</IonItem>
						<div className="price-increment-content">
							{formatPrice(productPrice) ? <p>{formatPrice(productPrice)}</p> : null}
							{quantity >= 1 && max > 1 && multiMax > 1 ? (
								<div className={formatPrice(productPrice) ? 'incrementer-box' : 'incrementer-box-top'}>
									<Incrementer
										allowNegative={false}
										data={{ groupIndex, choiceIndex, multiSelectionPermitted }}
										disabled={shouldBeDisabled}
										maxLimit={Math.min(multiMax, (quantity || 0) + max - groupQuantity)}
										onUpdate={this.onIncrementerChoiceUpdate}
										quantity={quantity || 0}
									/>
								</div>
							) : null}
						</div>
					</div>
				);
			} else {
				return (
					<div className={`sub-item ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''} `}>
						<IonItem lines="none" key={makeKey(choiceIndex, sku, groupIndex)}>
							<div tabIndex="-1"></div>
							{Basket.isProductUnsnoozed(item) ? (
								<>
									{multiSelectionPermitted ? (
										<IonCheckbox
											disabled={shouldBeDisabled}
											color="primary"
											slot="start"
											checked={isChecked}
											onIonChange={(event) => {
												this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event);
											}}
										/>
									) : (
										<IonRadio slot="start" className="details-radio" color={isWebConfig() ? 'primary' : 'white'} value={choiceIndex} checked={isChecked} />
									)}
								</>
							) : (
								<div className="checkbox-placeholder"></div>
							)}
							<div className="ion-text-wrap up">
								<NormalText className="single-item item-details-product-label">{__(getProductName(item, profile))}</NormalText>
								{allergensData && allergensData.length > 0 ? (
									<>
										<PillGroup items={allergensData} borderColor="primary" />
									</>
								) : null}
							</div>
						</IonItem>
						<div className="price-increment-content">
							<NormalText>{formatPrice(item.productPrice) ? formatPrice(item.productPrice) : null}</NormalText>
						</div>
					</div>
				);
			}
		});
		if (multiSelectionPermitted) {
			return <div className="item-details-group-wrapper">{allChoices}</div>;
		} else {
			//radio
			return (
				<div className="item-details-group-wrapper">
					<IonRadioGroup
						allowEmptySelection={true}
						value={selectedChoices && selectedChoices[groupIndex] && selectedChoices[groupIndex].length > 0 ? choices.findIndex((i) => i.sku === selectedChoices[groupIndex][0].sku) : -1}
						onIonChange={(event) => {
							this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event);
						}}
					>
						{allChoices}
					</IonRadioGroup>
				</div>
			);
		}
	};

	drawGroupLabel = (menuGroupItem, groupIndex) => {
		const { validationErrors } = this.state;
		const { __, profile } = this.props;
		let { description, min, max, maximumSelection, minimumSelection } = menuGroupItem;
		min = min ?? minimumSelection;
		max = max ?? maximumSelection;
		const itemDontHaveMinMax = (!isDefined(min) && !isDefined(max)) || (min === 0 && max === 0);
		// let minMaxlabel = (isDefined(min) ? __('min') + ':' + min : '') + (isDefined(max) ? ', ' + __('max') + ':' + max : '')
		let minMaxlabel = null;

		if (isDefined(min) && isDefined(max)) {
			if ((min === 0 && max === 0) || (min === 0 && max === -1)) {
				minMaxlabel = null;
			}
			if (min === 0 && max > 0) {
				minMaxlabel = __('Select up to') + ' ' + max + ' ' + (max === 1 ? __('item (optional)') : __('items (optional)'));
			}
			if (min > 0 && min === max) {
				minMaxlabel = __('Select') + ' ' + min + ' ' + (min === 1 ? __('option') : __('options'));
			}
			if ((min > 0 && max === 0) || max === -1) {
				minMaxlabel = __('Select at least') + ' ' + min + ' ' + (min === 1 ? __('option') : __('options'));
			}
			if (min > 0 && max > 0 && min !== max) {
				minMaxlabel = __('Select between') + ' ' + min + ' ' + __('and') + ' ' + max + ' ' + __('options');
			}
		} else {
			minMaxlabel = null;
		}

		return (
			<>
				<div lines="none" className="basket-group-label">
					<Subtitle className="bold block">{getProductDescription(menuGroupItem, profile).replace(/(<([^>]+)>)/gi, '')}</Subtitle>
					{itemDontHaveMinMax ? null : isDefined(minMaxlabel) && minMaxlabel !== '' ? (
						<>
							<NormalText className={validationErrors[groupIndex] ? 'field-error' : ''}>{minMaxlabel}</NormalText>
						</>
					) : null}
				</div>
				{itemDontHaveMinMax && validationErrors[groupIndex] ? <div className="field-error">{__(validationErrors[groupIndex])}</div> : null}
			</>
		);
	};

	drawMenuDealGroups = (menuGroupItem, index) => {
		// multiSelectionPermitted = true  --> only one item must be selected
		const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
		if (isChoicesGroupValid(menuGroupItem)) {
			if (isDefined(multiSelectionPermitted)) {
				return (
					<div className={this.state.validationErrors[index] ? 'group-required' : ''} key={index}>
						{this.drawGroupLabel(menuGroupItem, index)}
						{this.drawGroupChoices(menuGroupItem, multiSelectionPermitted, index)}
					</div>
				);
			} else {
				return this.drawGroupLabel(menuGroupItem);
			}
		}
	};

	constructBasketItem = () => {
		const { item } = this.props;
		const { quantity, selectedChoices, instructions } = this.state;
		// const item = item

		return {
			item,
			quantity,
			selectedChoices,
			instructions,
		};
	};

	addToOrder = () => {
		const newBasketItem = this.constructBasketItem();
		const validationErrors = validateItem(newBasketItem);
		const { item } = this.props;
		if (validationErrors.errorCount > 0) {
			this.setState({ validationErrors: validationErrors.errors }, () => {
				this.setShowValidationAlert(true);
			});
		} else {
			if (Basket.getOrderType() === 'browse-menu' || (Basket.itemsCount() === 0 && isEmptyObject(this.props.ikentooMenu))) {
				this.props.dispatch(storeItemWeb(newBasketItem));
				forwardTo('/delivery-options');
			} else {
				addToBasket(newBasketItem);
				if (isWebConfig()) {
					this.props.closeModal();
				} else {
					forwardTo('/order', { selectedMenu: item?.selectedMenu || [] });
				}
			}
		}

		let allergensCodes = item.itemRichData ? (item.itemRichData.allergenCodes ? item.itemRichData.allergenCodes : []) : [];
		const modifierCodes = [...new Set(newBasketItem.selectedChoices.map((group) => group.map((item) => item?.itemRichData?.allergenCodes).flat()).flat())].filter(Boolean);
		if (allergensCodes.length > 0 || modifierCodes.length > 0) {
			let allergensData = [{ allergens: this.state.allergensCodes }, { sku: item.sku }];
			if (isDefined(newBasketItem.selectedChoices)) {
				newBasketItem.selectedChoices.forEach((group) => {
					group.forEach((item) => {
						if (isDefined(item.itemRichData) && isDefined(item.itemRichData.allergenCodes) && item.itemRichData.allergenCodes.length > 0) {
							setAllergen([{ allergens: item.itemRichData.allergenCodes }, { sku: item.sku }]);
						}
					});
				});
			}
			setAllergen(allergensData);
		}
	};

	setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });

	scrollToRequiredField = () => {
		const requiredGroups = document.querySelectorAll('.group-required');
		document.querySelectorAll('.group-required').forEach((el) => el.classList.add('with-error'));
		if (!isWebConfig()) {
			document.querySelector('.item-detals-background').scrollTo({
				top: requiredGroups[0].offsetTop + 420,
				left: 0,
				behavior: 'smooth',
			});
		} else {
			document.querySelector('.item-details-content').scrollTo({
				top: requiredGroups[0].offsetTop + 210,
				left: 0,
				behavior: 'smooth',
			});
		}
	};
	getShowAddToOrderVisibilityStatus = () => {
		const availableOrderModules = getConfig().delivery.filter((d) => !d.isRemoved && !d.isDisabled && !d.isHiddenFromSidebar);
		const availableOrderModulesHiddenFromSidebar = getConfig().delivery.filter((d) => !d.isRemoved && !d.isDisabled && d.isHiddenFromSidebar);
		if (availableOrderModules.length > 0) {
			return true;
		}
		if (availableOrderModulesHiddenFromSidebar.length > 0) {
			if (isEmptyObject(Basket.getRestaurant())) {
				return false;
			} else {
				return true;
			}
		}
	};
	render() {
		const { __, profile, hideIncrementer, item } = this.props;
		const { quantity, price, showValidationAlert, allergensCodes } = this.state;
		const menuDealGroups = item.menuDealGroups ? item.menuDealGroups : [];
		const isAddToOrderBtnValid = validateItem(this.constructBasketItem()).errorCount === 0;
		const showOrderNowButton = Basket.getOrderType() === 'browse-menu' ? Basket.getRestaurant()?.show_order_now_button : true;
		let showAddToOrder = this.getShowAddToOrderVisibilityStatus(getConfig().delivery);
		return (
			<>
				<div className="item-details-card-content">
					<IonList className="item-details-card-list">
						<NormalText
							className="item-details-card-description"
							dangerouslySetInnerHTML={{
								__html: getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
							}}
						></NormalText>

						{allergensCodes.length > 0 ? (
							<>
								<Subtitle className="bold">{__('Allergens')}</Subtitle>
								<IonItem lines="none">
									<div tabIndex="-1"></div>
									<PillGroup items={allergensCodes} borderColor="primary" />
								</IonItem>
							</>
						) : null}

						{menuDealGroups.map(this.drawMenuDealGroups)}
						{getConfig().flags.specialInstructions.isDisplayed && showOrderNowButton ? (
							<>
								{this.state.instructions === '' ? (
									<IonButton
										onClick={() => {
											this.setState({ specialInstructionsModalOpen: true });
										}}
										fill="clear"
										color="primary"
										className="link no-borders add-instructions-button capitalized underlined  transparent"
									>
										{__('Add special instructions')}
									</IonButton>
								) : (
									<>
										<IonItem lines="none">
											<div tabIndex="-1"></div>
											<Subtitle className="bold">{__('Special Instructions')}</Subtitle>
										</IonItem>
										<div
											className="box-content special-instructions-wrapper"
											onClick={() => {
												this.setState({
													specialInstructionsModalOpen: true,
												});
											}}
										>
											<NormalText>{this.state.instructions}</NormalText>
											<IonButton
												color="primary"
												fill="clear"
												className="capitalized underlined transparent no-borders"
												onClick={() => {
													this.setState({
														specialInstructionsModalOpen: true,
													});
												}}
											>
												{__('Edit')}
											</IonButton>
										</div>
									</>
								)}
								<Modal
									onDidDismiss={() => this.setState({ specialInstructionsModalOpen: false })}
									className="special-instructions-modal"
									isOpen={this.state.specialInstructionsModalOpen}
								>
									<Title>{__('Special Instructions')}</Title>
									<Spacer size={1} />
									<IonItem class="input-field-wrapper" lines="none">
										<IonTextarea onIonChange={this.instructionsChange} rows={5} placeholder={__('Enter your instructions')} value={this.state.modalInstructions}></IonTextarea>
									</IonItem>
									<Spacer size={1} />
									<IonButton
										color="primary"
										expand="block"
										className="uppercase"
										onClick={() => {
											this.setState({
												instructions: this.state.modalInstructions,
												specialInstructionsModalOpen: false,
											});
										}}
									>
										{' '}
										{__('Continue')}
									</IonButton>
								</Modal>
							</>
						) : null}
					</IonList>
				</div>
				<div className="mobile-only" style={{ height: '190px' }}></div>

				{!hideIncrementer && showOrderNowButton ? (
					<>
						{showAddToOrder && (
							<div
								style={{ ...(getConfig().frenchDisclaimer?.dashboardDisclaimerImage ? { paddingBottom: '40px' } : null) }}
								className="item-details-actions flex-col-wrapper flex-align-center flex-justify-between"
							>
								<Incrementer allowNegative={false} quantity={quantity} onUpdate={this.onIncrementerUpdate} />
								<FlexSpacer size={20} />
								<IonButton
									disabled={quantity < 1}
									className={'item-details-add-to-order break-spaces ' + (isAddToOrderBtnValid ? '' : 'disabled')}
									size="full"
									shape="round"
									onClick={!isAddToOrderBtnValid ? this.scrollToRequiredField : this.addToOrder}
								>
									{`${Basket.getOrderType() === 'browse-menu' || (Basket.itemsCount() === 0 && isEmptyObject(this.props.ikentooMenu)) ? __('Start New Order') : __('Add to Order')}${
										price ? ` - ${price}` : ''
									}`}
								</IonButton>
								{getConfig().frenchDisclaimer?.dashboardDisclaimerImage && (
									<div className="dashboard-disclaimer-wrapper">
										<img src={getConfig().frenchDisclaimer?.dashboardDisclaimerImage}></img>
									</div>
								)}
							</div>
						)}
					</>
				) : null}
				<IonAlert
					isOpen={showValidationAlert}
					onDidDismiss={() => this.setShowValidationAlert(false)}
					header={__('Validation')}
					message={__('Please check any required options')}
					buttons={[{ text: __('OK'), role: 'cancel', cssClass: 'secondary' }]}
				/>
			</>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		profile: store.profile.profile,
		restaurantsUpdated: store.restaurants.restaurantsUpdated,
		basketUpdated: store.orders.basketUpdated,
		allergens: store.restaurants.allergens,
		storedItemWeb: store.orders.storedItemWeb,
		ikentooMenu: store.restaurants.ikentooMenu || {},
	};
};

export const ItemDetailsRaw = connect(mapStateToProps)(withTranslation(itemDetailsContent));

class itemDetails extends Component {
	constructor(props) {
		super(props);
		this.x = React.createRef();
		this.state = {
			nameVisible: true,
		};
	}
	onContentScrollHandler() {
		if (!this.isScrolledIntoView(this.x.current)) {
			this.setState({ nameVisible: false });
		} else {
			this.setState({ nameVisible: true });
		}
	}
	isScrolledIntoView(el) {
		var rect = el.getBoundingClientRect();
		var elemTop = rect.top;
		var elemBottom = rect.bottom;
		var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
		return isVisible;
	}
	shouldComponentUpdate(prevProps, prevState) {
		if (this.state.nameVisible !== prevState.nameVisible) {
			return true;
		} else {
			return false;
		}
	}
	render() {
		const { location, __, profile } = this.props;
		const item = location.state;
		const { selectedMenu } = item;
		let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
		if (image && image.indexOf('http://') !== -1) {
			image = image.replace(/http:\/\//g, 'https://');
		}
		return (
			<Layout headerTitle={__('Item Details')} scrollY={false} color="transparent" noPadding contentClassName="item-details-wrapper">
				<div className="item-details-main-wrapper">
					<div className={this.state.nameVisible ? ' item-details-header' : 'active item-details-header'}>
						<div className="item-details-back">
							<IonButton className="solo-button" color="white" onClick={() => forwardTo('/order', { selectedMenu })}>
								<IonIcon slot="icon-only" icon={arrowBack} />
							</IonButton>
						</div>
						<div
							style={{
								transition: !this.state.nameVisible ? 'transform 0.3s ease-in-out' : 'none',
								display: 'flex',
								alignItems: 'center',
								marginLeft: '20px',
								transform: this.state.nameVisible ? 'translateY(-200%)' : 'translateY(0)',
							}}
						>
							<NormalText style={{ fontSize: '18px' }}>
								<strong>{__(getProductName(item, profile))}</strong>
							</NormalText>
						</div>
					</div>
					<div
						className="scrollable-y item-detals-background"
						onScroll={() => {
							this.onContentScrollHandler();
						}}
					>
						<div className="item-details-info">
							{image && image !== '' ? (
								<div className="item-details-img">
									<img alt="item details" src={image} />
								</div>
							) : (
								<div className="item-details-img">
									<img style={{ objectFit: 'contain' }} alt="item details" src={Basket.getRestaurant()?.menu_item_placeholder_image ?? this.props.clientProfile.logo_image} />
								</div>
							)}
							<div className="item-details-name" ref={this.x}>
								<Title>{__(getProductName(item, profile))}</Title>
							</div>
							<div className="item-details-price">
								<NormalText>{formatPrice(item.productPrice)}</NormalText>
							</div>
						</div>

						<ItemDetailsRaw item={item} profile={profile} />
					</div>
				</div>
			</Layout>
		);
	}
}

const stateToProps = (store) => {
	return {
		profile: store.profile.profile,
		clientProfile: store.common.clientProfile,
		ikentooMenu: store.restaurants.ikentooMenu || {},
	};
};

export default connect(stateToProps)(withTranslation(itemDetails));
