import { Capacitor, Plugins } from '@capacitor/core';
import { IonAlert, IonButton, IonButtons, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonToolbar } from '@ionic/react';
import { chevronBack, chevronForward, chevronForwardOutline, logoStackoverflow } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { version as packageJsonVersion } from '../../../package.json';
import { getConfig } from '../../appConfig';
import defaultImg from '../../assets/images/gray-avatar.png';
import { NormalText, SmallText, StrongText } from '../../components/common';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { checkBackgroundColor, forwardTo, getDefaultRoute, isEmptyObject, isWebConfig, padNumber } from '../../lib/utils';
import { bookDeliveryDriver, logout, setCommonProp, setDeliveryOption, updateDriverCountdown } from '../../store/actions';
import { CLEAR_GIFT_VOUCHER_DATA, SET_COMMON_MODAL, SET_COMMON_PROP } from '../../store/constants';
import './index.css';
import SmallDrawer from './smallDrawer';
import delivery from '../../screens/delivery';

const collectionIcon = require('../../assets/images/collection.svg');
const deliveryIcon = require('../../assets/images/delivery.svg');
const tableIcon = require('../../assets/images/table.svg');
const logo5LoyaltyDark = require('../../assets/images/5loyalty-black.svg');
const logo5LoyaltyWhite = require('../../assets/images/5loyalty-white.svg');

const openExternalLink = (url) => window.open(url, '_system', 'location=yes');
const qm = require('../../assets/images/qm.svg');

const { Device } = Plugins;

const handleGuestMenuClick = (props, route) => {
	props.dispatch({
		type: SET_COMMON_MODAL,
		modal: 'guestUserRedirectModalOpen',
		value: true,
	});
};
const NavItem = withRouter(({ history, item, hideIcon, handleLogout, className, __, is_guest, _props }) => {
	let selected = history.location.pathname === item.path;
	if (item?.selectedUrlKeyword) {
		selected = history.location.pathname.includes(item?.selectedUrlKeyword);
	}
	return (
		<IonMenuToggle key={item.path} autoHide="false">
			<IonItem
				button
				className={'nav-item' + (selected ? ' okx-nav-selected' : '') + (className ? ' ' + className : '')}
				onClick={() =>
					item.fn === 'logout' ? handleLogout() : item.isLink ? openExternalLink(item.path) : is_guest ? handleGuestMenuClick(_props, item.path) : forwardTo(item.path, item.state)
				}
			>
				<div tabIndex="-1"></div>
				{hideIcon ? null : <IonIcon className="nav-icon" slot="start" icon={item.icon ? item.icon : qm} />}
				<NormalText className="nav-label">{__(item.label)}</NormalText>
			</IonItem>
		</IonMenuToggle>
	);
});

class Drawer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			appDeviceVersion: '',
			showPopup: false,
			menuVisible: true,
			logBoxOpen: false,
			profile_image_url: this.props.profile.profile_image_url || defaultImg,
			logo_image: this.props.clientProfile.logo_image || '',
			logo_image_light: this.props.clientProfile.logo_image_light || this.props.clientProfile.logo_image || '',
			isLogoClicked: false,
		};
		this.handleLogout = this.handleLogout.bind(this);
		this.handleModal = this.handleModal.bind(this);
	}
	setDeliveryOption = (option) => {
		this.props.dispatch(setDeliveryOption(option));
		Basket.setDeliveryOption(option);
		Basket.setOrderType(option.id);
		forwardTo(option.route);
	};
	handleLogout() {
		this.props.dispatch(logout());
		const defaultRoute = getDefaultRoute(this.props.navConfig);
		forwardTo(defaultRoute.path);
	}

	handleModal(val) {
		this.setState({ showPopup: val });
	}

	async componentDidMount() {
		const styles = this.props.clientStyles.colors?.['--okx-menu-background'];
		if (styles) {
			this.setState({ backgroundColor: styles });
		}
		const theme = this.props.clientStyles.theme || 'light';
		if (theme) {
			this.setState({ theme });
		}
		if (Basket.getDeliveryOption()?.id === 'gift-vouchers' && isEmptyObject(Basket.gift_voucher_data)) {
			Basket.reset();
		}

		const info = await Device.getInfo();
		const appDeviceVersion = info.appVersion;
		this.setState({ appDeviceVersion: appDeviceVersion }, () => {
			this.checkVerison();
		});
		if (this.props.location.pathname == '/order' && isWebConfig() && this.state.menuVisible == true) {
			this.toggleMenu();
		}
		if (this.props.location.pathname == '/gift-vouchers' && isWebConfig() && this.state.menuVisible == true) {
			this.toggleMenu();
		}
	}

	addZeros = (arr = []) =>
		arr.map((i, index) => {
			// e.g. 1.23.8
			// skip first number (app version) (e.g. 1)
			// add zeros only to patch (e.g. 23) or minor (e.g. 8)
			if (index !== 0) {
				return padNumber(i, 3);
			}
			return i;
		});

	isAppVersionValid = (apiVersion = '', appVersion = '') => {
		let ret = true;
		if (apiVersion && appVersion && apiVersion !== '' && appVersion !== '') {
			const apiVersionInt = parseInt(this.addZeros(apiVersion.split('.')).join(''), 10);
			const appVersionInt = parseInt(this.addZeros(appVersion.split('.')).join(''), 10);
			ret = appVersionInt >= apiVersionInt;
			// eslint-disable-next-line no-console
			console.log(
				'APP VERSION:' +
					'\n    isValid:    ' +
					ret +
					'\n    platform:   ' +
					(Capacitor.platform !== 'web' ? 'MOBILE' : 'WEB') +
					'\n    device:     (' +
					typeof appVersion +
					')-> ' +
					appVersion +
					' (int: ' +
					appVersionInt +
					')' +
					'\n    apiversion: (' +
					typeof apiVersion +
					')-> ' +
					apiVersion +
					' (int: ' +
					apiVersionInt +
					')',
			);
		} else {
			// eslint-disable-next-line no-console
			console.error('Skip version checking.');
		}
		return ret;
	};

	checkVerison = () => {
		const { appDeviceVersion } = this.state;
		if (Capacitor.platform !== 'web') {
			if (!this.isAppVersionValid(this.props.appVersion, appDeviceVersion) && appDeviceVersion !== '') {
				this.handleModal(true);
			}
		} else {
			// web version checking
			if (!this.isAppVersionValid(this.props.appVersion, packageJsonVersion)) {
				this.handleModal(true);
			}
		}
	};

	componentDidUpdate(prevProps) {
		if (getConfig().flags.hasDriverBooking && Basket.order_type.includes('delivery') && this.props.location.pathname == '/order') {
			this.props.dispatch(bookDeliveryDriver());
		}
		if (localStorage.getItem('driver_countdown') && !Basket.order_type.includes('delivery')) {
			localStorage.removeItem('driver_countdown');
			this.props.dispatch(updateDriverCountdown(0));
		}
		if (this.props.profile.is_guest) {
			if (
				this.props.location.pathname !== '/order-completed' &&
				this.props.location.pathname !== '/checkout' &&
				this.props.location.pathname !== '/gift-voucher' &&
				this.props.location.pathname !== '/card-add' &&
				this.props.location.pathname !== '/contact-details' &&
				this.props.location.pathname !== '/create-account' &&
				this.props.location.pathname !== '/split-bill' &&
				this.props.location.pathname !== '/split-bill-by-amount' &&
				this.props.location.pathname !== '/split-bill-by-items' &&
				this.props.location.pathname !== '/table-overview' &&
				this.props.location.pathname !== '/bill-completed' &&
				this.props.location.pathname !== '/table-bill-pay' &&
				this.props.location.pathname !== '/feedback'
			) {
				this.props.dispatch(logout(() => {}, '/login'));
			}
		}
		if (this.props.location.pathname !== '/bill-completed' && localStorage.getItem('table_payment_data')) {
			localStorage.removeItem('table_payment_data');
		}
		if (this.props.appVersion !== prevProps.appVersion) {
			this.checkVerison();
		}

		if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
			if (this.props.profile.profile_image_url) {
				this.setState({ profile_image_url: this.props.profile.profile_image_url });
			} else {
				this.setState({ profile_image_url: defaultImg });
			}
		}
		if (prevProps.location !== this.props.location) {
			if (this.props.location.pathname == '/order' && isWebConfig() && this.state.menuVisible) {
				this.toggleMenu();
			}
			if (this.props.location.pathname == '/gift-vouchers' && isWebConfig() && this.state.menuVisible) {
				this.toggleMenu();
			}
		}
		if (
			this.props.guestUserActionsModalOpen &&
			![
				'/click-and-collect',
				'/click-and-collect-scheduled',
				'/order-to-table',
				'/delivery',
				'/scheduled-delivery',
				'/pick-up-at-counter',
				'/delivery-address-add',
				'/delivery-address-check',
			].includes(this.props.location.pathname)
		) {
			this.props.dispatch(setCommonProp('guestUserActionsModalOpen', false));
		}
	}

	toggleMenu = () => {
		this.setState({ menuVisible: !this.state.menuVisible }, () => {
			let drawer = this.state.menuVisible ? '--okx-drawer-max-width' : '--okx-small-drawer-max-width';
			document.documentElement.style.setProperty('--okx-drawer-width', `var(${drawer})`);
		});
	};

	toggleLogBox = () => {
		const { auth } = this.props;
		const { loggedIn } = auth;
		if (loggedIn) {
			this.setState({ logBoxOpen: !this.state.logBoxOpen });
		} else {
			forwardTo('/login');
		}
	};

	handleNavLogoClick = (path) => {
		forwardTo(path, { isLogoClicked: true });
	};
	payTableSelected = () => {
		if (
			this.props.history.location.pathname == '/table-bill-pay' ||
			this.props.history.location.pathname == '/split-bill' ||
			this.props.history.location.pathname == '/split-bill-by-amount' ||
			this.props.history.location.pathname == '/split-bill-by-items' ||
			this.props.history.location.pathname == '/bill-completed' ||
			this.props.history.location.pathname == '/table-overview'
		) {
			return true;
		} else {
			return false;
		}
	};
	isItemActive = (id) => {
		let active = false;
		let activeDeliveryType = 'charter-delivery';
		if (Basket.order_type.includes('scheduled-delivery')) {
			activeDeliveryType = 'scheduled-delivery';
		}
		switch (id) {
			case activeDeliveryType:
				if (
					this.props.history.location.pathname === '/delivery' ||
					this.props.history.location.pathname === '/scheduled-delivery' ||
					this.props.history.location.pathname === '/scheduled-delivery-time' ||
					this.props.history.location.pathname === '/delivery-address-add' ||
					this.props.history.location.pathname === '/delivery-address-check' ||
					this.props.history.location.pathname === '/delivery-time'
				) {
					active = true;
				}
				break;
			case 'table':
				if (this.props.history.location.pathname === '/order-to-table') {
					active = true;
				}
				break;
			case 'collection':
				if (this.props.history.location.pathname === '/click-and-collect') {
					active = true;
				}
				break;
			case 'scheduled-collection':
				if (this.props.history.location.pathname === '/click-and-collect-scheduled') {
					active = true;
				}
				break;
			case 'pick-up-at-counter':
				if (this.props.history.location.pathname === '/pick-up-at-counter') {
					active = true;
				}
				break;
			case 'browse-menu':
				if (this.props.history.location.pathname === '/browse-menu') {
					active = true;
				}
				break;
		}
		return active;
	};
	getDeliveryOptionsIcon = (option) => {
		let icon;
		switch (option.id) {
			case 'charter-delivery':
				icon = deliveryIcon;
				break;
			case 'table':
				icon = tableIcon;
				break;
			default:
				icon = collectionIcon;
		}
		return icon;
	};
	handleViewMenu = () => {
		if (
			(Basket.order_type.includes('collection') && Basket.getOrderTime() === 'Invalid date') ||
			(Basket.order_type.includes('delivery') && !Basket.getDeliveryAddress()) ||
			(Basket.order_type.includes('table') && !Basket.getTableNumber()) ||
			Basket.order_type.includes('browse-menu')
		) {
			Basket.reset();
		}
		forwardTo('/order');
	};

	render() {
		const { auth, __, navConfig, clientProfile } = this.props;
		const { showPopup, appDeviceVersion, menuVisible, logo_image, logo_image_light } = this.state;
		const { loggedIn } = auth;
		const defaultRoute = getDefaultRoute(navConfig);
		const groupTitle = [
			{ id: 1, title: 'Loyalty and reward' },
			{ id: 2, title: 'Account' },
			getConfig().flags.hasGiftVouchers ? { id: 4, title: 'eGift Vouchers' } : null,
			{ id: 3, title: clientProfile.buisiness_name },
		].filter(Boolean);
		const routes = navConfig.routes.filter((route) => !!route.path && !route.notInDrawer);
		let logoColor = '';
		if (this.state.theme !== 'custom') {
			if (this.props.clientStyles.invertSideMenu) {
				logoColor = checkBackgroundColor(this.props.clientStyles.colors?.['--ion-color-primary']);
			} else {
				logoColor = this.state.theme == 'dark' ? 'white' : 'dark';
			}
		} else {
			logoColor = this.state.backgroundColor ? checkBackgroundColor(this.state.backgroundColor) : 'dark';
		}
		return (
			<IonMenu className={`drawer-menu drawer-menu-${logoColor}`} side="start" type="overlay" contentId="main">
				{menuVisible ? (
					<>
						<IonHeader>
							<IonToolbar>
								{/*<IonTitle>{ getConfig().theme.nav.label }</IonTitle>*/}
								<div
									className="nav-logo"
									style={{
										backgroundImage: `url(${logoColor === 'white' ? logo_image_light : logo_image})`,
									}}
									onClick={() => this.handleNavLogoClick(defaultRoute?.path)}
								></div>
								<span onClick={() => this.toggleMenu()} className="icon-placeholder collapse-drawer-icon">
									<IonIcon color={logoColor} icon={chevronBack} />
								</span>
								<IonButtons slot="end">
									<IonMenuToggle>
										<IonButton button clear>
											<IonIcon color={logoColor} slot="icon-only" icon="close" />
										</IonButton>
									</IonMenuToggle>
								</IonButtons>
							</IonToolbar>
						</IonHeader>

						<div className="sidebar-menu-content">
							{getConfig().flags.hasViewMenuButton && getConfig().flags.hasOrdering && (
								<IonMenuToggle autoHide="false">
									<IonButton onClick={() => this.handleViewMenu()} className="uppercase drawer-menu-order-button">
										{__('View menu')}
									</IonButton>
								</IonMenuToggle>
							)}
							<IonList lines="none">
								<div>
									{getConfig().flags.hasOrdering && (
										<>
											{getConfig().delivery.filter((d) => !d.isRemoved && !d.isHiddenFromSidebar).length > 0 && (
												<>
													<NormalText className="nav-title">{__('Order')}</NormalText>
													{getConfig().delivery.map((d, index) => (
														<div key={index}>
															{!d.isRemoved && !d.isHiddenFromSidebar && (
																<IonMenuToggle key={index} autoHide="false">
																	<IonItem
																		button
																		disabled={d.isDisabled}
																		className={'nav-item' + (this.isItemActive(d.id) ? ' okx-nav-selected' : '')}
																		onClick={() => {
																			if (this.props.profile.is_guest) {
																				handleGuestMenuClick(this.props, d.route);
																			} else {
																				Basket.getOrderType();
																				this.props.dispatch({
																					type: CLEAR_GIFT_VOUCHER_DATA,
																				});
																				Basket.reset();
																				this.setDeliveryOption(d);
																			}
																		}}
																	>
																		<IonIcon className="nav-icon" slot="start" icon={this.getDeliveryOptionsIcon(d)} />
																		<NormalText className="nav-label">{__(d.label)}</NormalText>
																	</IonItem>
																</IonMenuToggle>
															)}
														</div>
													))}
												</>
											)}
										</>
									)}
									{getConfig().flags.hasBillPay && (
										<div>
											<IonMenuToggle autoHide="false">
												<IonItem
													button
													className={'nav-item' + (this.payTableSelected() ? ' okx-nav-selected' : '')}
													onClick={() => {
														this.props.profile.is_guest ? handleGuestMenuClick(this.props, '/table-bill-pay') : forwardTo('/table-bill-pay');
													}}
												>
													<IonIcon className="nav-icon" slot="start" icon={collectionIcon} />
													<NormalText className="nav-label">{__('Pay your bill')}</NormalText>
												</IonItem>
											</IonMenuToggle>
										</div>
									)}
								</div>
								{groupTitle.map((group, index) => (
									<div key={index}>
										<NormalText className="nav-title">{__(group.title)}</NormalText>
										{routes.map((route) => {
											if (route.group === group.id) {
												return <NavItem _props={this.props} is_guest={this.props.profile.is_guest} __={__} key={route.path} item={route} />;
											}
										})}
									</div>
								))}
								{getConfig().sidebarLinks?.map((item, index) => (
									<div key={index}>
										<NormalText key={index} className=" nav-title">
											{__(item.title)}
										</NormalText>
										<>
											{item.items.map((el, i) => (
												<IonItem button key={i} onClick={() => openExternalLink(el.link)} className={'nav-item'}>
													<span
														className="external-icon"
														dangerouslySetInnerHTML={{
															__html: el.icon,
														}}
													></span>
													<NormalText className="nav-label">{__(el.label)}</NormalText>
												</IonItem>
											))}
										</>
									</div>
								))}
								<a className="fiveloyalty-logo-wrapper" href="https://www.5loyalty.com" target="_blank" rel="noopener noreferrer">
									{logoColor === 'white' ? <img alt="logo 5Loyalty white" src={logo5LoyaltyWhite} /> : <img alt="logo 5Loyalty dark" src={logo5LoyaltyDark} />}
								</a>
								<IonLabel size="small" slot="start">
									v{Capacitor.platform !== 'web' && appDeviceVersion !== '' ? appDeviceVersion : packageJsonVersion}
								</IonLabel>
							</IonList>
						</div>
					</>
				) : (
					<SmallDrawer logoColor={logoColor} toggleMenu={this.toggleMenu} />
				)}
				<IonAlert
					isOpen={showPopup}
					onDidDismiss={() => this.handleModal(false)}
					header={__('App version')}
					message={__('Your app is out of date. Please update.')}
					buttons={[
						{
							text: __('OK'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.handleModal(false),
						},
					]}
				/>
				<IonAlert
					isOpen={this.props.guestUserRedirectModalOpen}
					onDidDismiss={() =>
						this.props.dispatch({
							type: SET_COMMON_MODAL,
							modal: 'guestUserRedirectModalOpen',
							value: false,
						})
					}
					header={__('You are guest user')}
					message={__('Your progress will be deleted')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: __('OK'),
							handler: () => forwardTo('/login'),
						},
					]}
				/>
			</IonMenu>
		);
	}
}

const stateToProps = (state) => {
	const { auth, profile } = state.profile;
	const { appVersion, clientProfile, clientProfileUpdated, navConfig, clientStyles, guestUserRedirectModalOpen, guestUserActionsModalOpen } = state.common;
	return {
		auth,
		appVersion,
		profile,
		clientProfile,
		clientProfileUpdated,
		navConfig,
		clientStyles,
		guestUserRedirectModalOpen,
		guestUserActionsModalOpen,
	};
};

export default connect(stateToProps)(withRouter(withTranslation(Drawer)));
