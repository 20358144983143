import axios from 'axios';
import { isDefined } from './utils';
import { getConfig } from '../appConfig';
import packageInfo from '../../package.json';

let axiosInstance;

const baseURL = getConfig().api.baseURL;
const wordPressBaseURL = getConfig().api.wordPressBaseURL;
const nativeWallet = getConfig().api.nativeWallet;
const addToGoogleWallet = `${nativeWallet}/google-wallet`;
const addToAppleWallet = `${nativeWallet}/apple-wallet`;
const endpoints = {
	login: '/api-token-auth', //post
	register: '/me/register', //post
	getProfile: '/me', //get
	changePassword: '/me', //put
	changeProfile: '/me', //put
	resetPassword: '/me/reset_password', //put
	deleteCard: '/delete_payment_card', //put
	sendFeedback: '/feedback/create', //post,
	firebaseToken: '/firebase-token', //post,
	appVersion: '/appversion', //get,
	social: '/social', //get,
	restaurants: '/restaurants/all', //get
	terms: '/terms', //get,
	privacyPolicy: '/privacy', //get
	faq: '/faq', //get
	loyaltyInfo: '/loyalty_info',
	unlockRewardsInfo: '/unlock_rewards_info',
	milestoneRewardsInfo: '/milestone_rewards_info',
	rewards: '/reward_milestones', //get
	history: '/transactions/history', //get
	ikentooMenu: '/ikentoo_menu/', //get
	ikentooMenusForLocation: '/ikentoo_menus/', //get
	getPaymentCards: '/orders/list_payment_cards', //post
	addPaymentCard: '/orders/create_payment_card', //post
	removePaymentCard: '/orders/delete_payment_card', //post
	createOrder: '/order/create', //post
	createStripeOrder: '/order/create_stripe_order', //post
	updateStripeOrder: '/order/update_stripe_order', //post
	createYocoOrder: '/order/create_yoco_order', //post
	orderHistory: '/me/orders', //get
	getMultiVenueOrder: '/me/multi_basket_order',
	validateEmail: '/me/validate_email', //get
	sendVoucherCode: '/voucher/code', //put,
	referFriend: '/refer_friend', //post
	getVouchers: '/me/vouchers', //get
	publicStripeKey: '/public_stripe_key', //get
	frontEndAppConfig: '/front_end_app_config', //get
	getTranslations: '/translation_json', //get
	addDeliveryAdress: '/me/add_new_address', //put
	getRestaurantSnoozeData: '/ikentoo_menu/get_snooze_data', //get
	getOrderProduction: '/order_production_length',
	postCodeCheck: '/order/postcode_check', //post
	getNearestLocation: '/order/get_nearest_restaurant_location', //put
	getDeliveryRangeType: '/get_delivery_range_type', //get
	locationCodeCheck: '/order/location_code_check', // post
	addPickupPoint: '/me/add_pickup_point', // put
	allergens: '/allergens', //get
	removeDeliveryAddress: '/orders/remove_delivery_address', //post
	getDefaultMenuId: '/get_default_menu_id', //get
	getDefaultMenu: '/get_default_menu', //get
	cancelOrder: '/orders/cancel_order', // post,
	getLastOrder: '/orders/get_last_order', // get
	client: '/client', //get,
	paymentInfo: '/update_payment_info',
	sendGiftVoucher: '/gift_voucher/create',
	resendGiftVoucher: '/gift_voucher/resend',
	redeemGiftVoucher: '/gift_voucher/redeem_code',
	getSentGiftVouchers: '/gift_voucher/sent_gift_vouchers',
	updateGiftVoucher: '/gift_voucher/update',
	checkGiftVoucherBalance: '/gift_voucher/check_balance',
	subscriptionPackages: '/subscription/packages', // get
	createSubscription: '/subscription/create_stripe_subscription', // post
	cancelSubscription: '/subscription/cancel', // put
	payMembership: '/subscription/memberships', // post
	checkDiscount: '/order/check_discount', //post
	checkMultiBasketDiscount: '/order/check_multibasket_discount',
	removeProfile: '/me/delete_user', // post
	floorPlans: '/floor_plans/', //get
	checkTables: '/check_table/', //get
	setTablePayMethod: '/set_pay_method/', //put
	getTableById: '/get_table_by_id/', //get
	payTable: '/create_table_order/', //post
	selectTableItem: '/update_selected_items/',
	sendTablePaymentEmail: '/send_table_payment_email/',
	refreshScanToken: '/refresh_scan_token',
	buyReward: '/reward/buy',
	updateProfileImage: '/me/update_profile_image',
	getTax: '/order/get_tax_breakdown',
	getTaxData: '/order/get_tax',
	bookDriver: '/order/prebook_delivery',
	getGiftCards: '/me/gift_cards',
	refreshToken: '/api-token-refresh',
};

const createAxiosInstance = (token) => {
	const headers = isDefined(token)
		? {
				Authorization: 'JWT ' + token,
		  }
		: {};
	axiosInstance = axios.create({
		headers,
		timeout: 300000,
	});
	return axiosInstance;
};

export const isAuthorizationTokenSet = () => {
	if (axiosInstance && axiosInstance.defaults && axiosInstance.defaults.headers) {
		const authHeader = axiosInstance.defaults.headers['Authorization'] || axiosInstance.defaults.headers.common['Authorization'];
		return !!authHeader;
	}
	return false;
};

export const isFieldValid = (fieldName, errors = {}) => !isDefined(errors[fieldName]);

export const getErrorMessage = (fieldName, errors = {}) => (!isFieldValid(fieldName, errors) ? errors[fieldName] : '');

const api = {
	getTranslations: async () => {
		const response = await createAxiosInstance().get(`${wordPressBaseURL}/en/dictionary/${getConfig().envs.APP_DOMAIN}`);
		return response.data;
	},
	login: async (username, password, socialAuth) => {
		const response = await createAxiosInstance().post(baseURL + endpoints.login, {
			username,
			password,
			socialAuth,
			version: packageInfo.version,
		});
		axiosInstance = createAxiosInstance(response.data.token);
		return response;
	},
	register: async (userData) => {
		const response = await createAxiosInstance().post(baseURL + endpoints.register, { ...userData, version: packageInfo.version });
		axiosInstance = createAxiosInstance(response.data.token);
		return response;
	},
	logout: () => {
		// eslint-disable-next-line no-console
		console.log('logout');
	},
	resetPassword: async (email) => await axiosInstance.put(baseURL + endpoints.resetPassword, { email, version: packageInfo.version }),
	getProfile: async () => await axiosInstance.get(baseURL + endpoints.getProfile + `?version=${packageInfo.version}`),
	updateProfile: async (data = {}) => await axiosInstance.put(baseURL + endpoints.changeProfile, { ...data, version: packageInfo.version }),
	sendFirebaseToken: async (data) => await axiosInstance.post(baseURL + endpoints.firebaseToken, { ...data, version: packageInfo.version }),
	getAppVersion: () => axiosInstance.get(baseURL + endpoints.appVersion + `?version=${packageInfo.version}`).then((res) => res.data.data.app_version),
	getSocialLinks: () => axiosInstance.get(baseURL + endpoints.social + `?version=${packageInfo.version}`).then((res) => res.data.social_link_json),
	sendFeedback: async (data = {}) => await axiosInstance.post(baseURL + endpoints.sendFeedback, { ...data, version: packageInfo.version }),
	getRestaurants: () => axiosInstance.get(baseURL + endpoints.restaurants + `?version=${packageInfo.version}`).then((res) => res.data.data),
	getTerms: (locale, client_name) =>
		axios
			.create()
			.get(baseURL + '/' + locale + '/terms/' + client_name + `?version=${packageInfo.version}`, {
				responseType: 'text',
			})
			.then((res) => res.data),
	getPrivacyPolicy: (locale, client_name) =>
		axios
			.create()
			.get(baseURL + '/' + locale + '/privacy/' + client_name + `?version=${packageInfo.version}`, { responseType: 'text' })
			.then((res) => res.data),
	getFaq: (locale, client_name) =>
		axios
			.create()
			.get(baseURL + '/' + locale + '/faqs/' + client_name + `?version=${packageInfo.version}`, { responseType: 'text' })
			.then((res) => res.data),

	getAllergensInfo: (locale, client_name) =>
		axios
			.create()
			.get(wordPressBaseURL + '/' + locale + '/allergen-index/' + client_name + `?version=${packageInfo.version}`, {
				responseType: 'text',
			})
			.then((res) => res.data),
	getRewards: () => axiosInstance.get(baseURL + endpoints.rewards).then((res) => res.data.data),
	getIkenooMenu: (menuId, businessLocationId) =>
		axiosInstance.get(baseURL + endpoints.ikentooMenu + menuId + '/location/' + businessLocationId + `?version=${packageInfo.version}`).then((res) => res.data.data),
	getIkentooMenusForLocation: (businessLocationId) =>
		axiosInstance.get(baseURL + endpoints.ikentooMenusForLocation + 'location/' + businessLocationId + `?version=${packageInfo.version}`).then((res) => res.data.data),
	getHistory: (page) => axiosInstance.get(baseURL + endpoints.history + `${page ? `/${page}` : ''}?version=${packageInfo.version}`).then((res) => res.data),
	getPaymentCards: () => axiosInstance.post(baseURL + endpoints.getPaymentCards, { version: packageInfo.version }).then((res) => res.data.data),
	addPaymentCard: (cardData) => axiosInstance.post(baseURL + endpoints.addPaymentCard, { ...cardData, version: packageInfo.version }).then((res) => res.data.data),
	removePaymentCard: (cardData) => axiosInstance.post(baseURL + endpoints.removePaymentCard, { ...cardData, version: packageInfo.version }).then((res) => res.data),
	createOrder: (orderData) => axiosInstance.post(baseURL + endpoints.createOrder, { ...orderData, version: packageInfo.version }).then((res) => res.data),
	createStripeOrder: (orderData) => axiosInstance.post(baseURL + endpoints.createStripeOrder, { ...orderData, version: packageInfo.version }).then((res) => res.data),
	updateStripeOrder: (orderId) => axiosInstance.post(baseURL + endpoints.updateStripeOrder, { ...orderId, version: packageInfo.version }).then((res) => res.data),
	createYocoOrder: (orderData) => axiosInstance.post(baseURL + endpoints.createYocoOrder, { ...orderData, version: packageInfo.version }).then((res) => res.data),
	getOrderHistory: (page) => axiosInstance.get(baseURL + endpoints.orderHistory + `${page ? `/${page}` : ''}?version=${packageInfo.version}`).then((res) => res.data),
	sendCode: async (data) => await axiosInstance.put(baseURL + endpoints.sendVoucherCode, data).then((res) => res.data),
	sendRefer: async (data) => await axiosInstance.post(baseURL + endpoints.referFriend, { ...data }).then((res) => res.data),
	getVouchers: async () => await axiosInstance.get(baseURL + endpoints.getVouchers + `?version=${packageInfo.version}`).then((res) => res.data),
	validateEmail: async () => await axiosInstance.get(baseURL + endpoints.validateEmail + `?version=${packageInfo.version}`),
	getPublicStripeKey: () => axiosInstance.get(baseURL + endpoints.publicStripeKey + `?version=${packageInfo.version}`).then((res) => res.data.data.public_stripe_key),
	getFrontEndAppConfig: async () => {
		if (!axiosInstance) {
			createAxiosInstance();
		}
		const res = await axiosInstance.get(baseURL + endpoints.frontEndAppConfig + `?version=${packageInfo.version}`);
		return res.data.data;
	},
	// getTranslations: () => axiosInstance.get(baseURL + endpoints.getTranslations).then(res => res.data.data.translation_json),
	// getTranslations: () => axiosInstance.get(wordPressBaseURL + '/en/dictionary/' + getConfig().envs.APP_DOMAIN + `?version=${packageInfo.version}`).then((res) => res.data),
	addDeliveryAdress: (data) => axiosInstance.put(baseURL + endpoints.addDeliveryAdress, { ...data, version: packageInfo.version }),
	getRestaurantSnoozeData: () => axiosInstance.get(baseURL + endpoints.getRestaurantSnoozeData + `?version=${packageInfo.version}`).then((res) => res.data.data),
	getOrderProduction: () => axiosInstance.get(baseURL + endpoints.getOrderProduction + `?version=${packageInfo.version}`).then((res) => res.data.data.order_production_mins),
	postCodeCheck: (data) => axiosInstance.post(baseURL + endpoints.postCodeCheck, { ...data, version: packageInfo.version }),
	getNearestLocation: (data) => axiosInstance.put(baseURL + endpoints.getNearestLocation, { ...data, version: packageInfo.version }),
	getDeliveryRangeType: () => axiosInstance.get(baseURL + endpoints.getDeliveryRangeType + `?version=${packageInfo.version}`).then((res) => res.data.data.delivery_range_type),
	locationCodeCheck: (data) => axiosInstance.post(baseURL + endpoints.locationCodeCheck, { ...data, version: packageInfo.version }),
	addPickupPoint: (data) => axiosInstance.put(baseURL + endpoints.addPickupPoint, { ...data, version: packageInfo.version }),
	getAllergens: async () => await axiosInstance.get(baseURL + endpoints.allergens + `?version=${packageInfo.version}`).then((res) => res.data),
	removeDeliveryAddress: (postalCode) => axiosInstance.post(baseURL + endpoints.removeDeliveryAddress, postalCode).then((res) => res.data),
	getDefaultMenuId: () => axiosInstance.get(baseURL + endpoints.getDefaultMenuId + `?version=${packageInfo.version}`).then((res) => res.data.data.default_menu_id),
	getDefaultMenu: (menuId) => axiosInstance.get(baseURL + endpoints.getDefaultMenu + '/' + menuId + `?version=${packageInfo.version}`).then((res) => res.data.data),
	cancelOrder: (data) => axiosInstance.post(baseURL + endpoints.cancelOrder, { ...data, version: packageInfo.version }).then((res) => res.data.data),
	getLastOrder: (id) => axiosInstance.get(baseURL + endpoints.getLastOrder + '/' + id + `?version=${packageInfo.version}`).then((res) => res.data.data),
	getClient: () => axiosInstance.get(baseURL + endpoints.client + `?version=${packageInfo.version}`).then((res) => res.data.data),
	sendGiftVoucher: async (data) => await axiosInstance.post(baseURL + endpoints.sendGiftVoucher, { ...data, version: packageInfo.version }).then((res) => res.data),
	resendGiftVoucher: async (data) => await axiosInstance.put(baseURL + endpoints.resendGiftVoucher, { ...data, version: packageInfo.version }).then((res) => res.data),
	updateGiftVoucher: async (data) => await axiosInstance.put(baseURL + endpoints.updateGiftVoucher, { ...data, version: packageInfo.version }).then((res) => res.data),
	redeemGiftVoucher: async (data) => await axiosInstance.post(baseURL + endpoints.redeemGiftVoucher, { ...data, version: packageInfo.version }).then((res) => res.data),
	getSentGiftVouchers: () => axiosInstance.get(baseURL + endpoints.getSentGiftVouchers + `?version=${packageInfo.version}`).then((res) => res.data),
	getSubscriptions: async () => await axiosInstance.get(baseURL + endpoints.subscriptionPackages + `?version=${packageInfo.version}`),
	createSubscription: async (data) => await axiosInstance.post(baseURL + endpoints.createSubscription, { ...data, version: packageInfo.version }),
	cancelUserSubscription: (data) => axiosInstance.put(baseURL + endpoints.cancelSubscription, { ...data, version: packageInfo.version }),
	payMembership: (membership) => axiosInstance.post(baseURL + endpoints.payMembership, membership),
	checkDiscount: async (data) => await axiosInstance.post(baseURL + endpoints.checkDiscount, { ...data, version: packageInfo.version }),

	checkMultiBasketDiscount: async (data) => await axiosInstance.post(baseURL + endpoints.checkMultiBasketDiscount, { ...data, version: packageInfo.version }),

	addToGoogleWallet: async (data) => await axiosInstance.post(addToGoogleWallet, { ...data, appversion: packageInfo.version }).then((res) => res.data),
	addToAppleWallet: async (data) => await axiosInstance.post(addToAppleWallet, { ...data, appversion: packageInfo.version }, { responseType: 'blob' }).then((res) => res.data),
	removeProfile: async (data) => await axiosInstance.post(baseURL + endpoints.removeProfile, { ...data, version: packageInfo.version }),
	getFloorPlans: (data) => axiosInstance.post(baseURL + endpoints.floorPlans, data).then((res) => res.data.data),
	getTableData: (tableNumber, businessLocationId) => {
		return axiosInstance.get(baseURL + endpoints.checkTables + tableNumber + '/' + businessLocationId + `?version=${packageInfo.version}`).then((res) => res.data.data);
	},
	setTablePayMethod: (data) => {
		return axiosInstance.put(baseURL + endpoints.setTablePayMethod, { ...data, version: packageInfo.version }).then((res) => res.data);
	},
	getTableById: (tableBillId) => {
		return axiosInstance.get(baseURL + endpoints.getTableById + tableBillId + `?version=${packageInfo.version}`).then((res) => res.data.data);
	},
	postSlectItems: (tableNumber, businessLocationId, data) => {
		return axiosInstance.post(baseURL + endpoints.selectTableItem + tableNumber + '/' + businessLocationId, { ...data, version: packageInfo.version }).then((res) => res.data.data);
	},
	payTable: (data) => {
		return axiosInstance.post(baseURL + endpoints.payTable, { ...data, version: packageInfo.version }).then((res) => res.data);
	},
	sendTablePaymentEmail: (data) => {
		return axiosInstance.post(baseURL + endpoints.sendTablePaymentEmail, { ...data, version: packageInfo.version }).then((res) => res.data);
	},
	checkGiftVoucherBalance: (data) => {
		return axiosInstance.post(baseURL + endpoints.checkGiftVoucherBalance, { ...data, version: packageInfo.version }).then((res) => res.data);
	},
	refreshScanToken: () => {
		return axiosInstance.get(baseURL + endpoints.refreshScanToken + `?version=${packageInfo.version}`).then((res) => res.data.data);
	},
	buyReward: async (rewardId) => await axiosInstance.put(`${baseURL}${endpoints.buyReward}/${rewardId}?version=${packageInfo.version}`).then((res) => res.data),
	updateProfileImage: async (data) => await axiosInstance.post(baseURL + endpoints.updateProfileImage, data),

	getUnlockRewardsInfo: async () => await axiosInstance.get(baseURL + endpoints.unlockRewardsInfo + `?version=${packageInfo.version}`).then((res) => res.data),
	getMilestoneRewardsInfo: async () => await axiosInstance.get(baseURL + endpoints.milestoneRewardsInfo + `?version=${packageInfo.version}`).then((res) => res.data),
	getLoyaltyInfo: async () => await axiosInstance.get(baseURL + endpoints.loyaltyInfo + `?version=${packageInfo.version}`).then((res) => res.data),
	getTax: async (data) => await axiosInstance.post(`${baseURL}${endpoints.getTax}`, data).then((res) => res.data),
	getTaxData: async (uuid) => await axiosInstance.get(`${baseURL}${endpoints.getTaxData}/${uuid}?version=${packageInfo.version}`).then((res) => res.data),
	getMultiVenueOrder: async (data) => await axiosInstance.post(`${baseURL}${endpoints.getMultiVenueOrder}`, data).then((res) => res.data),
	bookDriver: async (data) => await axiosInstance.post(`${baseURL}${endpoints.bookDriver}`, data).then((res) => res.data),

	getGiftCards: async () => await axiosInstance.get(baseURL + endpoints.getGiftCards + `?version=${packageInfo.version}`).then((res) => res.data),
	refreshToken: async (data) => await axiosInstance.post(baseURL + endpoints.refreshToken + `?version=${packageInfo.version}`, data).then((res) => res.data),
};

api.createAxiosInstance = createAxiosInstance;

export default { ...api };
