import React, { Component } from 'react';
import { IonButton, IonInput, IonItem } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import Modal from '../../components/modal';
import ReactDOM from 'react-dom';
import { getConfig } from '../../appConfig';
import Basket from '../../lib/basket';
import Mobiscroll from '../../components/mobiscroll';
import { Spacer, StrongText } from '../common';
import './index.css';

const { SelectOption } = Mobiscroll;

class TableNumberModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tableNumber: '',
		};
	}

	render() {
		const { __ } = this.props;
		const tableNumberPicker = getConfig().flags.tableNumberPicker || false;
		const tablePlan = getConfig().tablePlan || [];
		let tableInput = true;
		let tableData = [];
		if (tableNumberPicker) {
			tableInput = false;
			if (Basket.has_multi_basket) {
				tableData = tablePlan.map((zone) => zone.tables.map((table) => ({ value: table.id, text: `${__('Table')} ${table.table_number}`, group: zone.name }))).flat();
			} else {
				if (Basket.getRestaurant()) {
					const tablePlanJson = Basket.getRestaurant().table_plan_json || [];
					tablePlanJson.forEach((table) => {
						tableData.push({ text: table.name, value: table.value });
					});
				}
			}
		}
		return (
			<Modal backdropDismiss={false} className="table-number-picker" isOpen={true}>
				<>
					<Spacer size={2} />
					<StrongText className="block">{__('Before proceeding with your order, please confirm your table number.')}</StrongText>
					<Spacer size={2} />

					{tableInput ? (
						<div className={`input-field-container ${Basket.has_multi_basket ? 'multi-venue-table-picker' : ''}`}>
							<IonItem lines="none" className="input-field-wrapper">
								<IonInput
									className="table-number-input"
									onIonChange={(e) =>
										this.setState({
											tableNumber: e.target.value,
										})
									}
									value={this.state.tableNumber}
									type="number"
									placeholder={__('Enter table number')}
								/>
							</IonItem>
						</div>
					) : (
						<>
							{Basket.has_multi_basket ? (
								<div className={`input-field-container multi-venue-table-picker`}>
									<SelectOption
										inputStyle="box"
										display="inline"
										{...(getConfig().tablePlan && getConfig().tablePlan.length > 1 && { group: { header: true, groupWheel: false, clustered: false } })}
										data={tableData}
										value={this.state.tableNumber || null}
										onSet={(e, a) => this.setState({ tableNumber: a.getVal() })}
										onInit={() => {
											if (tableData.length > 0) {
												if (!this.state.tableNumber && tableData[0]) {
													this.setState({
														tableNumber: tableData[0].value,
													});
												}
											}
										}}
									/>
								</div>
							) : (
								<div className={`input-field-container   `}>
									<SelectOption
										inputStyle="box"
										display="inline"
										label={__('Table Number')}
										setText={__('OK')}
										cancelText={__('Cancel')}
										data={tableData}
										value={this.state.tableNumber}
										onSet={(e, a) => this.setState({ tableNumber: a.getVal() })}
									/>
								</div>
							)}
						</>
					)}
				</>
				<Spacer size={2} />
				<IonButton
					disabled={!this.state.tableNumber || this.state.tableNumber == ''}
					expand="block"
					onClick={() => {
						Basket.setTableNumber(this.state.tableNumber, true);
					}}
				>
					{__('Confirm')}
				</IonButton>
			</Modal>
		);
	}
}
export default withTranslation(TableNumberModal);
